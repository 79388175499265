import * as AT from "@/store/actionTypes";

export const leadFormMixins = {
  data() {
    return {
      storageId: "microsite"
    };
  },
  methods: {
    validatePhone() {
      if (this.phone && this.phone.charAt(0) < "6") {
        this.phone = null;
      } else if (this.phone.length > 10) {
        this.phone = this.phone.slice(0, 10);
      }
    },
    isAllowedToSubmit(formId) {
      let lastSubmission = localStorage.getItem("lastSubmitted");
      if (!lastSubmission) {
        localStorage.setItem("lastSubmitted", JSON.stringify({ [formId]: new Date().toISOString() }));
        return true;
      }
      try {
        let data = JSON.parse(lastSubmission);
        if (Date.now() - new Date(data[formId]).getTime() >= 1 * 10 * 60 * 60 * 1000) {
          data[formId] = new Date().toISOString();
          localStorage.setItem("lastSubmitted", JSON.stringify(data));
          return true;
        } else if (!(formId in data)) {
          localStorage.setItem("lastSubmitted", JSON.stringify({ ...data, ...{ [formId]: new Date().toISOString() } }));
          return true;
        }
      } catch (error) {
        if (Date.now() - new Date(lastSubmission).getTime() >= 1 * 10 * 60 * 60 * 1000) {
          localStorage.setItem("lastSubmitted", JSON.stringify({ [formId]: new Date().toISOString() }));
          return true;
        }
      }
      return false;
    },
    removeSubmission(formId) {
      let lastSubmission = localStorage.getItem("lastSubmitted");
      try {
        let data = JSON.parse(lastSubmission);
        delete data[formId];
        localStorage.setItem("lastSubmitted", JSON.stringify(data));
      } catch (error) {
        console.error(error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    submitForm() {
      this.$store
        .dispatch(AT.SUBMIT_FORM)
        .then(() => {
          this.$store.dispatch(AT.RESET_FORM);
          this.$store.dispatch(AT.SNACKBAR, {
            color: "success",
            text: "Submitted Successfully",
            timeout: 1000
          });
        })
        .catch(() => {});
    },
    firePixel() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(AT.FIRE_PIXEL, { eventType: "ty", event: "submit" })
          .then((res) => resolve(res))
          .catch((error) => reject(error));
      });
    },
    trimPhoneNumber(phoneNumber) {
      if (phoneNumber) {
        if (phoneNumber.startsWith("91") && phoneNumber.length > 10) {
          return phoneNumber.substring(2);
        } else if (phoneNumber.startsWith("+91")) {
          return phoneNumber.substring(3);
        } else {
          return phoneNumber;
        }
      }
      return null;
    },
    extractLocationParams(locationString) {
      let parts = locationString.split("|");
      this.userData.city = parts[0] || "";
      this.userData.pincode = parts[2] || "";
    },
    setDataToStorage(key, value) {
      localStorage.setItem(key, value);
    },
    getDataFromStorage(key) {
      return localStorage.getItem(key);
    },
    shuffleOptions(options) {
      for (let i = options.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [options[i], options[j]] = [options[j], options[i]];
      }
      return options;
    }
  }
};
export const inputRulesMixins = {
  data() {
    return {
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid"
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => (v && v.length === 10) || "Invalid Phone number",
        (v) => (v && v.charAt(0) >= "6") || "Invalid Phone number"
      ],
      required: [
        (v) => !!v || " This field required",
        (v) => !v || /^[A-Za-z. ]+$/.test(v.trim()) || "Invalid Input",
        (v) => (v && v.trim().length > 0) || "This field cannot be empty"
      ],
      radioRequired: [(v) => !!v || "Please select an option"],
      nameRules: [(v) => !!v || "Name is required", (v) => !v || /^[A-Za-z ]+$/.test(v.trim()) || "Invalid Input"],
      tncRules: [(v) => !!v || "Please accept the Terms of Service to proceed"],
      stateRules: [(v) => !!v || "State is required"],
      cityStateRules: [
        (v) => !!v || " This field required",
        (v) => !v || /^[A-Za-z ]+$/.test(v.trim()) || "Invalid Input"
      ],
      pincodeRules: [(v) => !!v || "Pincode is required", (v) => (v && v.length === 6) || "Invalid Pincode"],
      qsnRules: [(v) => !!v || v == 0 || "Please select your answer"],
      otpRules: [(v) => !!v || " Please Enter the OTP"]
    };
  }
};
