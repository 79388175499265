<template class="pa-0 ma-0">
  <div>
    <v-card class="quiz-box ma-2">
      <v-card-item class="text-center pa-0 ma-0">
        <p class="font-weight-bold" style="color: rgb(217, 57, 26); padding: 0 45px">Thank you for Participating</p>
        <p class="ma-1" style="font-size: 12px; color: #1a2c72; text-align: center" v-if="!checkCoupons">
          Getting Best coupons for you .....
        </p>
        <div class="ma-3 text-start" v-if="couponData.length > 3">
          <p class="ma-1" style="font-size: 12px; color: #1a2c72; text-align: center">
            You can now redeem the below coupons
          </p>
          <v-row>
            <v-col v-for="(item, index) in couponData" :key="index" cols="4">
              <v-card
                class="copoun"
                @click="openOfferDetail(item)"
                :style="{
                  opacity: item.isRedeemed ? 0.5 : 1
                }"
              >
                <div>
                  <div class="img-wrapper">
                    <v-img class="align-end offer-img" :src="item.display_image_url" cover> </v-img>
                  </div>

                  <div class="offer-heading" style="overflow: hidden">
                    {{ item.title }}
                    <p
                      v-if="vendor === 'jio'"
                      class="pl-1"
                      style="font-size: 5px; font-family: sans-serif; line-height: 1"
                    >
                      {{ item.description }}
                    </p>
                  </div>
                  <div class="redeem-btn text-center mt-1">
                    <a style="color: #ffffff; text-decoration: none" @click="openOfferDetail(item)">Get Offer</a>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="" v-if="vendor == 'grabon'">
            <v-col cols="12" class="powered-by">
              <span>Powered By<v-img height="25" src="@/assets/grabon.png"></v-img></span>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <p class="ma-1" style="font-size: 12px; color: #1a2c72; text-align: center" v-if="checkCoupons">
            No Coupons available at the moment
          </p>
        </div>
      </v-card-item>
    </v-card>
    <div class="bottom-card-wrapper" v-if="showCouponDetails && selectedItem">
      <v-card class="bottom-card" ref="card">
        <v-row class="mt-1">
          <v-col cols="10" class="pa-4 d-flex justify-center align-center" style="height: 75px">
            <v-card :style="adjestLogo()">
              <v-img :src="selectedItem.display_image_url" cover> </v-img>
              <v-card-title class="text-h6"> </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="2" class="pb-0 pr-4 pb-3 pt-1 d-flex justify-center align-center pl-0">
            <div class="text-end d-flex justify-center align-center" @click="handleClose">
              <span class="mdi mdi-close text-h4"></span>
            </div>
          </v-col>
        </v-row>

        <div class="title_wraper">
          <p class="title">{{ selectedItem.title }}</p>
          <p class="pr-3" style="font-family: sans-serif; font-size: small; font-weight: 500" v-if="vendor == 'jio'">
            {{ formatDateWithMoment(selectedItem.expiry_date) }}
          </p>
        </div>

        <p class="text-body-2 pa-3">
          {{ truncatedText(selectedItem.description) }}
          <a class="text-body-2" href="#" @click.prevent="toggleShowMore" v-if="vendor == 'grabon'">
            {{ isExpanded ? "View less" : "Show more" }}
          </a>
        </p>

        <p v-if="selectedItem.offer_code" class="font-weight-bold your-code">Your Coupon code :</p>
        <div v-if="selectedItem.offer_code && vendor == 'grabon'" class="code-wrapper">
          <span class="code">{{ selectedItem.offer_code }}</span>
          <v-btn class="font-weight-bold" @click="copyText(selectedItem)"
            ><v-icon>mdi mdi-content-copy</v-icon>COPY CODE</v-btn
          >
        </div>
        <div v-if="selectedItem.offer_code && vendor == 'jio'" class="code-wrapper">
          <span style="filter: blur(3px)" class="code">{{ cropCode(selectedItem.offer_code) }}</span>
        </div>
        <div class="pl-5 pr-5 pt-1 how-to-wrapper">
          <p>How to Redeem?</p>
          <div v-html="redemptionSteps" class="steps-container"></div>
        </div>
        <div class="redeem-now mb-2">
          <v-btn color="primary" rounded style="font-weight: bold" :loading="isLoading" @click="copyText(selectedItem)">
            Copy Code & Redeem Now
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import * as AT from "@/store/actionTypes";
import { leadFormMixins } from "../../leadclients/mixins";
import moment from "moment";
export default {
  name: "CouponCard",
  mixins: [leadFormMixins],
  props: {
    requestId: {
      type: String,
      default: () => null
    },
    vendor: {
      type: String,
      default: () => null
    }
  },
  data: () => ({
    couponData: [],
    showCouponDetails: true,
    redemptionSteps: null,
    checkCoupons: false,
    selectedItem: null,
    isExpanded: false,
    isLoading: false,
    maxLines: 2
  }),
  mounted() {
    this.$store.dispatch(AT.SET_COUPON_DATA, { vendor: this.vendor, requestId: this.requestId }).then((couponData) => {
      this.checkCoupons = true;
      this.couponData = couponData;
      let data = this.getDataFromStorage("redeemedCoupons");
      if (data) {
        let parseData = JSON.parse(data);
        for (let title of parseData) {
          this.couponData = this.couponData.map((obj) => (obj.title === title ? { ...obj, isRedeemed: true } : obj));
        }
      }
    });
  },
  methods: {
    formatDateWithMoment(dateString) {
      const date = moment(dateString);

      return `Valid till ${date.format("Do MMMM YYYY")}`;
    },
    truncatedText(description) {
      if (!this.isExpanded && description.length > 100) {
        const limitedText = description.split(" ").slice(0, 100).join(" ");
        return limitedText + "...";
      }
      return description;
    },
    toggleShowMore() {
      this.isExpanded = !this.isExpanded;
    },
    getPercentage(title) {
      const regex = /\d+%/g;
      const percentages = title.match(regex);
      return percentages;
    },
    handleClose() {
      this.showCouponDetails = false;
      this.isExpanded = false;
    },
    openOfferDetail(selectedItem) {
      this.showCouponDetails = true;
      this.selectedItem = selectedItem;
      this.redemptionSteps = selectedItem.redemption_steps;
    },
    cropCode(code) {
      if (code.length <= 3) {
        return code;
      }
      const lastThree = code.slice(-2);
      const masked = "x".repeat(code.length - 2);
      return masked + lastThree;
    },
    copyText(coupon) {
      if (this.vendor === "jio") {
        this.isLoading = true;
        let data = this.getDataFromStorage("redeemedCoupons");
        if (data) {
          let parseData = JSON.parse(data);
          parseData.push(coupon.title);
          this.setDataToStorage("redeemedCoupons", JSON.stringify(parseData));
        } else {
          this.setDataToStorage("redeemedCoupons", JSON.stringify([coupon.title]));
        }
      }
      navigator.clipboard
        .writeText(coupon.offer_code)
        .then(() => {
          location.href = coupon.redemption_url;
        })
        .finally(() => {
          setTimeout(() => (this.isLoading = false), 5000);
        });
      this.$store.dispatch(AT.SNACKBAR, {
        color: "success",
        text: "Code Successfully Copied!",
        timeout: 500
      });
    },
    adjestLogo() {
      return {
        width: this.vendor == "grabon" ? "60%" : "25%",
        background: "none",
        "box-shadow": "none",
        "z-index": -1,
        "margin-left": "50px"
      };
    }
  }
};
</script>

<style scoped>
:deep(.v-snackbar__wrapper) {
  bottom: 40px;
}
.title_wraper {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f4f4f4;
  justify-content: space-between;
}
.title {
  font-size: 20px;
  padding: 10px 20px;
  font-weight: 700;
  background: #f4f4f4;
}
.title::before {
  content: "";
  position: absolute;
  margin-top: -9px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #f39c12, #e74c3c);
  transform: translateY(-50%);
  z-index: 0;
}

.title::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background: #3498db;
  margin-top: 35px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(44, 44, 44, 0.5);
  box-shadow: 0 0 1px #fefefe;
}

.redeem-now {
  width: 100%;
  text-align: center;
}
.your-code {
  padding: 5px 20px;
  font-size: 15px;
}
.how-to-wrapper {
  max-height: 150px;
  overflow: scroll;
  p {
    font-size: 14px;
    color: rgb(113, 113, 113);
  }
}
.copy-button {
  font-size: 17px;
  padding: 4px;
}

:deep(.steps-container) {
  font-family: Arial, sans-serif;
  border-radius: 8px;
}

:deep(.steps-container b) {
  font-size: 15px;
  text-decoration: underline;
}

:deep(.steps-container ul) {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
  font-family: sans-serif;
}

.code-wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
}
.code {
  border: dotted;
  background: rgb(226 222 222);
  font-family: "Times New Roman", Times, serif;
  padding: 3px 1pc;
  font-size: 17px;
  font-weight: bold;
}
.go-stps {
  font-size: 10px;
}
.bottom-card {
  bottom: 0;
  left: 0;
  text-align: start;
  width: 100%;
  border-radius: 30px 30px 0 0;
  min-height: 400px;
  position: absolute;
  opacity: 1;
  span {
    text-align: end;
  }
}
@keyframes smooth-appear {
  from {
    bottom: -100%; /* Start from off-screen */
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
.bottom-card-wrapper {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: rgba(225, 225, 225, 0.6);
  animation: smooth-appear 0.1s ease forwards;
}
.description {
  font-size: 4px;
}
.offer-imgs {
  -webkit-box-shadow: -1px 5px 15px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 5px 15px -15px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 5px 15px -15px rgba(0, 0, 0, 0.75);
}
.img-wrapper {
  height: 62px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 1px;
}
.offer-tag {
  z-index: 2;
  color: #c43c1e;
  text-align: center;
  height: 25px;
  width: 25px;
  position: absolute;
  background: #e6b002;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 8px;
  flex-wrap: wrap;
  line-height: 0%;
}
.percentage {
  font-size: 10px;
  font-weight: 600;
}
.up-to {
  font-size: 4px;
}
.redeem-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 8px;
  border-radius: 4px;
  height: 14px;
  justify-content: center;
  margin-left: 20%;
  width: 60%;
  font-weight: 700;
  padding: 1px 2px;
  background: #3535f3;
}
.offer-heading {
  font-size: 9px;
  user-select: none;
  text-align: center;
  color: #393636;
  height: 30px;
  text-overflow: ellipsis;
}
.copoun {
  border-radius: 10px;
  height: 115px;
  width: 90px;
}

.card-info {
  text-align: center;
  padding-right: 12px;
}
.powered-by {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;

  font-size: 10px;
}
</style>
